<template>
  <z-autocomplete
    v-model="journey"
    :items="journeyList"
    :hideDetails="true"
    label="Jornadas"
    itemValue="id"
    itemText="name"
    return-object
    @change="
      $emit('select-journey', journey);
      journey = '';
    "
  />
</template>

<script>
export default {
  inject: ["journeyList"],
  data() {
    return {
      journey: null,
    };
  },
};
</script>
